<template>
    <default-modal v-if="open" @close="close">
        <h3 slot="header" class="text-left text-xl leading-6 font-medium text-blue-900 pb-5">
            Envoyer fiche réservation à {{mainCandidate.full_name}}
            <span v-if="secondCandidate">
                et {{secondCandidate.full_name}}
            </span>
        </h3>
        <div slot="body" class="w-90vw lg:w-60vw">
            <div class="mt-4 text-left">
                <div class="flex">
                    <div class="m-1 block flex-initial">
                        <label for="sre-email" class="block text-sm font-medium leading-5 text-gray-700 mb-2">Email du
                            candidat 1</label>
                        <span>
                            {{mainCandidate.email}}
                        </span>
                    </div>
                    <div v-if="secondCandidate" class="m-1 block flex-initial">
                        <label for="sre-email" class="block text-sm font-medium leading-5 text-gray-700 mb-2">Email du
                            candidat 2</label>
                        <span>
                            {{secondCandidate.email}}
                        </span>
                    </div>
                </div>
                <div class="pt-2">
                    <label class="block text-sm font-medium leading-5 text-gray-700 mb-2">Ne pas envoyer l'e-mail au(x)
                        candidat(s)
                        <input type="checkbox" v-model="body.do_not_send_email" />
                    </label>
                </div>
                <div class="pt-2">
                    <label class="block text-sm font-medium leading-5 text-gray-700 mb-2">M'envoyer une copie du mail
                        ({{ user.email }}) (indépendant de la coche ci-dessus)
                        <input type="checkbox" v-model="body.send_copy_to_broker" />
                    </label>
                </div>
                <div class="text-left mt-4">
                    <label for="sre-subject" class="block text-sm font-medium leading-5 text-gray-700 mb-2">Sujet de
                        l'email</label>
                    <input type="text" id="sre-subject" v-model="body.subject" class="form-input w-full" />
                </div>
                <div class="mt-4">
                    <label for="body" class="block text-sm font-medium leading-5 text-gray-700">Contenu</label>
                    <div class="mt-1 rounded-md shadow-sm">
                        <textarea v-model="body.body" id="body" rows="20"
                            class="border p-2 block w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5 focus:outline-none focus:border-blue-400 rounded"></textarea>
                    </div>
                    <p v-if="body.do_not_send_email" class="mt-2 text-sm text-gray-500">
                        Ce message sera envoyé par email au(x) candidat(s).
                    </p>
                </div>
            </div>
        </div>
        <div slot="footer" class="mt-12 text-right">
            <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
                    <button @click="submit" :disabled="!isValid" type="button"
                        class="inline-flex justify-center w-full rounded-md border border-transparent px-8 py-2 leading-6 font-medium shadow-sm hover:bg-blue-600 text-gray-100 cursor-pointer focus:outline-none focus:border-gray-700 focus:shadow-outline-gray transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                        :class="submitButtonClass">

                        Valider
                    </button>
                </span>
                <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
                    <button @click="close" type="button"
                        class="inline-flex justify-center w-full rounded-md border border-gray-300 px-8 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-400 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                        Fermer
                    </button>
                </span>
            </div>
        </div>
    </default-modal>
</template>

<script>
import { mapGetters } from "vuex";
import DefaultModal from "@/components/global/modals/DefaultModal";
import Email from "@/mixins/Email";

export default {
    mixins : [Email],
    components: {
        DefaultModal,
    },
    data() {
        return {
            body: {
                emails: "",
                subject: "E-Promotion - Formulaire d'inscription",
                do_not_send_email: false,
                send_copy_to_broker: false,
                body:
                    "Votre pré-réservation a été validée par l'équipe e-Promotion. Veuillez remplir ce formulaire :",
            },
            isDisabled: false
        };
    },
    computed: {
        ...mapGetters("application", ["currentActionTarget", "currentActionType"]),
        ...mapGetters("auth", ["currentPromotion", "user"]),
        open(){
            return this.currentActionTarget && this.currentActionType === "sendReservationEmail";
        },
        mainCandidate(){
            return this.currentActionTarget.candidacy1.candidate;
        },
        secondCandidate(){
            return this.hasSecondCandidate ? this.currentActionTarget.candidacy2.candidate : false;
        },
        hasSecondCandidate() {
            return this.currentActionTarget.candidacy2 ? true : false;
        },
        notary() {
            return this.currentPromotion ? this.currentPromotion.notaries : null;
        },
        isValid() {
            if (this.isDisabled)
                return false;
            return this.body.body && this.body.body != "" && this.body.subject ? true : false;
        },
        submitButtonClass() {
            return this.isValid
                ? "bg-blue-400 hover:bg-blue-600 text-gray-100 cursor-pointer"
                : "bg-gray-300 text-gray-700 cursor-not-allowed";
        },
    },
    methods: {
        close() {
            this.body.body = "";
            this.body.do_not_send_email = false;
            this.$store.commit("application/SET_CURRENT_ACTION_TYPE", null);
        },
        submit() {
            this.isDisabled = true;
            this.$store.commit("loader/SET_LOADING", true);
            this.body.body = this.body.body.replace(/\n\r?/g, "<br />");

            this.$store.dispatch("app/executeCommand", {
                name : "sendReservationForm",
                params : {
                    entity : "application",
                    entity_id : this.currentActionTarget.id,
                    additionnal_fields : this.body
                }
            }).then((response) => {
                if (response.status === 200) {
                    this.close();
                }
                this.$store.commit("loader/SET_LOADING", false);
                this.$emit("update");
            }).catch((error) => {
                this.$store.commit("loader/SET_LOADING", false);
                this.$toast.open({
                    type: "error",
                    message: error,
                });
            });
            this.isDisabled = false;
        },
        renderBody() {
            let text = "";
            text +=
                "Nous vous félicitons pour votre choix et nous réjouissons de poursuivre ce beau projet avec vous. ";
            text +=
                "Vous n’êtes plus qu’à deux étapes de la réservation finale ! Nous vous invitons à nous communiquer, moyennant le formulaire ci-dessous, vos coordonnées complètes (celles des personnes signataires de l’acte d’achat). ";
            text +=
                "Afin de valider votre réservation, nous vous prions de verser votre acompte de réservation de [CHF XX’XXX.- pour les attiques et les villas ou de CHF XX’XXX.- pour les appartements] sur le compte du notaire en charge de cette promotion : \n\n";
            
            if (this.currentPromotion.main_notary) {
                text += "Banque : " + this.currentPromotion.main_notary.bank_name + "\n";
                text += "Titulaire : " + this.currentPromotion.main_notary.bank_holder + "\n";
                text += "IBAN : " + this.currentPromotion.main_notary.iban + "\n";
                text += "\n";
            } else {
                text += "[IBAN / RIB] \n\n";
            }

            text +=
                "Sauf réservation préalable par un tiers, votre réservation vous sera confirmée par écrit par Naef Immobilier SA dès confirmation de réception de l’acompte de réservation.\n";

            text += "Notaire en charge du projet : \n\n";

            if (this.currentPromotion.main_notary) {
                text += this.currentPromotion.main_notary.full_name + "\n";
                text += this.currentPromotion.main_notary.full_address + "\n";
                text += this.currentPromotion.main_notary.phone + "\n";
                text += "\n";
            } else {
                text += "[NOTAIRE] \n\n";
            }

            text +=
                "Nous restons tout naturellement à votre entière disposition pour tout complément d’information dont vous pourriez avoir besoin.\n\n";

            this.body.body = text;
        },
        renderSubject() {
            this.body.subject = `${this.currentPromotion.title} - Votre acquisition du lot ${this.currentActionTarget.lot.reference}`;
        },
    },

    watch: {
        open(val) {
            if (val) {
                this.render();
            }
        },
    },
};
</script>
