<template>
  <div class="p-4">
    <div class="flex justify-between mb-2">
      <div class="text-lg font-semibold text-blue-600">
        Immeubles et lots
      </div>
      <div class="flex">
        <a
          v-if="!selectedBuilding && user.admin"
          @click.prevent="openCreateBuilding"
          href="#"
          class="pb-2 pt-2 px-4 border border-transparent text-sm font-medium rounded-sm text-white bg-blue-400 hover:bg-blue-600 focus:outline-none focus:border-green-700 transition duration-150 ease-in-out"
          ><svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            class="h-4 w-4 inline-block fill-current align-middle mr-2"
          >
            <path
              d="M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
            ></path></svg
          ><span class="align-middle">Ajouter un immeuble</span></a
        >

        <div class="flex flex-row gap-3" v-if="selectedBuilding && !selectedLot">
          <a
            @click.prevent="openEditBuilding"
            v-if="user.admin"
            href="#"
            class="flex p-2 items-center justify-center text-sm font-medium rounded-sm text-white bg-blue-400 hover:bg-blue-600 transition duration-150 ease-in-out"
          >
            <svg
              class="h-4 w-4 inline-block fill-current align-middle mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
              ></path>
            </svg>
            <span class="align-middle">Modifier l'immeuble</span>
          </a>
          <a
            @click.prevent="openCreateLot"
            v-if="user.admin"
            href="#"
            class="pb-2 pt-2 px-4 border border-transparent text-sm font-medium rounded-sm text-white bg-blue-400 hover:bg-blue-600 focus:outline-none focus:border-green-700 transition duration-150 ease-in-out"
            ><svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              class="h-4 w-4 inline-block fill-current align-middle mr-2"
            >
              <path
                d="M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
              ></path></svg
            ><span class="align-middle">Ajouter un lot</span></a
          >          
        </div>
      </div>
      <div class="flex flex-row gap-3" v-if="selectedBuilding && selectedLot">
        <a
          @click.prevent="openEditLot"
          v-if="user.admin"
          href="#"
          class="flex p-2 items-center justify-center text-sm font-medium rounded-sm text-white bg-blue-400 hover:bg-blue-600 transition duration-150 ease-in-out"
        >
          <svg
            class="h-4 w-4 inline-block fill-current align-middle mr-2"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
            ></path>
          </svg>
          <span class="align-middle">Modifier le lot</span>
        </a>

        <a
          v-if="!selectedChildLot && user.admin"
          @click.prevent="openAddChildLot"
          href="#"
          class="flex p-2 items-center justify-center text-sm font-medium rounded-sm text-white bg-blue-400 hover:bg-blue-600 transition duration-150 ease-in-out"
          ><svg
            class="h-4 w-4 inline-block fill-current align-middle mr-2"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 9h4v2h-4v4H9v-4H5V9h4V5h2v4zm-1 11a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
            ></path>
          </svg>
          <span class="align-middle">Ajouter un lot associé</span>
        </a>

        <a
          v-if="!selectedChildLot && user.admin"
          @click.prevent="openAttributeChildLot"
          href="#"
          class="flex p-2 items-center justify-center text-sm font-medium rounded-sm text-white bg-blue-400 hover:bg-blue-600 transition duration-150 ease-in-out"
          ><svg 
            xmlns="http://www.w3.org/2000/svg" 
            class="h-6 w-6" 
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor">
            <path 
              stroke-linecap="round" 
              stroke-linejoin="round" 
              stroke-width="2" 
              d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
          </svg>
          <span class="align-middle">Associer un lot existant</span>
        </a>

        <a
          v-if="selectedChildLot && user.admin"
          @click.prevent="openDissociateChildLot"
          href="#"
          class="flex p-2 items-center justify-center text-sm font-medium rounded-sm text-white bg-red-500 hover:bg-red-600 transition duration-150 ease-in-out"
          ><svg 
            xmlns="http://www.w3.org/2000/svg" 
            class="h-6 w-6" 
            fill="none" 
            viewBox="0 0 24 24" 
            stroke="currentColor">
            <path 
              stroke-linecap="round" 
              stroke-linejoin="round" 
              stroke-width="2" 
              d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
          </svg>
          <span class="align-middle">Dissocier du lot principal</span>
        </a>

        <a
          @click.prevent="openDeleteLot"
          v-if="user.admin"
          href="#"
          class="flex p-2 items-center justify-center text-sm font-medium rounded-sm text-white bg-red-500 hover:bg-red-600 transition duration-150 ease-in-out"
        >
          <svg
            class="w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
              clip-rule="evenodd"
            ></path>
          </svg>
          <span class="align-middle">Supprimer</span>
        </a>
      </div>
    </div>

    <nav class="flex mb-6 px-1" aria-label="Breadcrumb">
      <ol class="flex items-center space-x-4">
        <li>
          <div>
            <a
              href="#"
              @click.prevent="clearBuilding"
              class="text-gray-400 hover:text-gray-500"
            >
              <!-- Heroicon name: solid/home -->
              <svg
                class="flex-shrink-0 h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"
                />
              </svg>
              <span class="sr-only">Home</span>
            </a>
          </div>
        </li>

        <li v-if="selectedBuilding">
          <div class="flex items-center">
            <!-- Heroicon name: solid/chevron-right -->
            <svg
              class="flex-shrink-0 h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <a
              href="#"
              @click.prevent="clearLot"
              class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
              >{{ selectedBuilding.title }} (ID immeuble: {{ selectedBuildingId }})</a
            >
          </div>
        </li>

        <li v-if="selectedLot">
          <div class="flex items-center">
            <!-- Heroicon name: solid/chevron-right -->
            <svg
              class="flex-shrink-0 h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <a
              href="#"
              @click.prevent="clearChildLot"
              class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
              aria-current="page"
              >{{ selectedLot.title }}</a
            >
          </div>
        </li>

        <li v-if="selectedChildLot">
          <div class="flex items-center">
            <!-- Heroicon name: solid/chevron-right -->
            <svg
              class="flex-shrink-0 h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fill-rule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
            <a
              href="#"
              class="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700"
              aria-current="page"
              >{{ selectedChildLot.title }}</a
            >
          </div>
        </li>
      </ol>
    </nav>

    <div v-if="promotion.buildings.length === 0">
      <p class="text-gray-800">Vous n'avez pas encore créé d'immeuble.</p>
    </div>

    <div>
      <div v-if="!selectedBuilding">
        <div
          v-for="building in promotion.buildings"
          :key="`building-${building.id}`"
          class="flex items-center px-4 py-3 hover:bg-gray-200 cursor-pointer rounded-md border border-gray-200 shadow-sm bg-gray-100 mb-3"
          @click="selectBuilding(building)"
        >
          <div class="text-gray-800 mr-3">
            <svg
              class="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
              ></path>
            </svg>
          </div>
          <div>
            {{ building.title }} (ID immeuble: {{ building.id }})
          </div>
        </div>
      </div>

      <div v-else-if="!selectedLot">
        <div
          v-for="lot in mainLots"
          :key="`lot-${lot.id}`"
          class="flex items-center px-4 py-3 hover:bg-gray-200 cursor-pointer rounded-md border border-gray-200 shadow-sm bg-gray-100 mb-3"
          @click="selectLot(lot)"
        >
          <div class="text-gray-800 mr-3">
            <svg
              class="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
              ></path>
            </svg>
          </div>
          <div>
            {{ lot.title }}
            <span class="text-gray-600 font-light"
              >(ID lot: {{ lot.id }} - ref public: {{ lot.reference }} - statut :
              {{ lot.status_text }})</span
            >
          </div>
        </div>
      </div>

      <div v-else-if="lotDisplay" class="px-2">
        <h2 class="text-xl text-gray-700 font-medium">
          {{ lotDisplay.title }}
        </h2>

        <p class="font-medium mt-4 text-blue-900">
          ID Lot : {{ lotDisplay.id }} <br>
          Reference : {{ lotDisplay.reference }} 
          <span v-if="lotDisplay.parent_id">
            <br>
            <span v-if="lotDisplay.price_included">Prix inclus dans le prix affiché du lot parent</span>
            <span v-else>Prix <strong>non</strong> inclus dans le prix affiché du lot parent</span>
          </span>
        </p>

        <div v-if="selectedChildLot">
          <p class="font-medium mt-4 mb-2 text-blue-900">Associé au lot :</p>
          <div class="flex items-center">
            <select-lot
              @change="updateSelectedChildLotParent"
              :lots="allPromotionLots"
              :initialValue="selectedChildLot.parent_id"
            ></select-lot>
            <button
              v-if="selectedChildLotParent && user.admin"
              type="button"
              @click="submitUpdateParentLot"
              class="rounded-sm flex py-2 px-4 items-center justify-center text-sm font-medium rounded-sm text-white bg-blue-400 hover:bg-blue-600 transition duration-150 ease-in-out"
            >
              Enregistrer
            </button>
          </div>
        </div>

        <p class="font-medium mt-4 text-blue-900">Informations :</p>
        <table class="min-w-full main--table">
          <thead class="bg-gray-200">
            <tr>
              <th>Etage</th>
              <th>Prix</th>
              <th v-if="lotDisplay.parent_id">Nom</th>
              <th>{{ surfacePPETitle }}</th>
              <th>{{ surfaceHabitableTitle }}</th>
              <th>Jardin</th>
              <th>Balcon</th>
              <th>Terrasse</th>
              <th>Exposition</th>
              <th>Lot PPE</th>
              <th>Feuillet PPE</th>
              <th>Nb pièces</th>
            </tr>
          </thead>
          <tbody class="text-center">
              <td class="py-2 px-0 font-base">{{ lotDisplay.floor.title }}</td>
              <td class="py-2 px-0 font-base">{{ lotDisplay.raw_price }} CHF</td>
              <td v-if="lotDisplay.parent_id" class="py-2 px-0 font-base">{{ lotDisplay.name_child }}</td>
              <td class="py-2 px-0 font-base">{{ lotDisplay.area_ppe }} m²</td>
              <td class="py-2 px-0 font-base">{{ lotDisplay.area_hab }} m²</td>
              <td class="py-2 px-0 font-base">{{ lotDisplay.area_jar }} m²</td>
              <td class="py-2 px-0 font-base">{{ lotDisplay.area_bal }} m²</td>
              <td class="py-2 px-0 font-base">{{ lotDisplay.area_ter }} m²</td>
              <td class="py-2 px-0 font-base">{{ lotDisplay.exposure }}</td>
              <td class="py-2 px-0 font-base">{{ lotDisplay.lot_ppe }}</td>
              <td class="py-2 px-0 font-base">{{ lotDisplay.feuillet_ppe }}</td>
              <td class="py-2 px-0 font-base">{{ lotDisplay.nb_rooms }}</td>
          </tbody>
        </table>

        <p v-if="!selectedChildLot" class="font-medium mt-4 mb-4">
          Lots associés
        </p>

        <div>
          <div
            v-for="childLot in lotDisplay.children"
            :key="`childLot-${childLot.id}`"
            class="flex items-center px-4 py-3 hover:bg-gray-200 cursor-pointer rounded-md border border-gray-200 shadow-sm bg-gray-100 mb-3"
            @click="selectChildLot(childLot)"
          >
            <div class="text-gray-800 mr-3">
              <svg
                class="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                ></path>
              </svg>
            </div>
            <div>{{ childLot.title }}</div>
          </div>
        </div>
      </div>
    </div>

    <add-building-modal
      :open="modals.createBuilding"
      @close="modals.createBuilding = false"
      :promotion="promotion"
      @submit="addBuilding"
    />
    <add-building-modal
      v-if="selectedBuilding"
      :open="modals.editBuilding"
      @close="modals.editBuilding = false"
      :promotion="promotion"
      :editBuilding="selectedBuilding"
      @submit="editBuilding"
    />
    <add-lot-modal
      v-if="selectedBuilding"
      :open="modals.createLot"
      @close="modals.createLot = false"
      :promotion="promotion"
      :building="selectedBuilding"
      @submit="addLot"
    />
    <add-lot-modal
      v-if="selectedBuilding"
      :open="modals.editLot"
      @close="modals.editLot = false"
      :promotion="promotion"
      :building="selectedBuilding"
      :editLot="selectedChildLot ? selectedChildLot : selectedLot"
      @submit="editLot"
    />
    <add-lot-modal
      v-if="selectedBuilding && selectedLot"
      :open="modals.addChildLot"
      @close="modals.addChildLot = false"
      :promotion="promotion"
      :building="selectedBuilding"
      :parentLot="selectedLot"
      @submit="addChildLot"
    />
    <attribute-child-lot-modal
      v-if="selectedBuilding && selectedLot"
      :open="modals.attributeChildLot"
      @close="modals.attributeChildLot = false"
      :promotion="promotion"
      :building="selectedBuilding"
      :editLot="selectedChildLot ? selectedChildLot : selectedLot"
      @submit="attributeChildLot"
    />
    <dissociate-child-lot-modal
      v-if="selectedBuilding && selectedLot && selectedChildLot"
      :open="modals.dissociateChildLot"
      @close="modals.dissociateChildLot = false"
      :lot="selectedChildLot"
      @submit="dissociateChildLot"
    />
    <delete-lot-modal
      v-if="selectedBuilding && selectedLot"
      :open="modals.deleteLot"
      :lot="selectedChildLot ? selectedChildLot : selectedLot"
      :building="selectedBuilding"
      @close="modals.deleteLot = false"
      :promotion="promotion"
      @submit="deleteLot"
    />
  </div>
</template>

<script>
import promotionApi from "@/api/promotion";

import AddBuildingModal from "@/components/Promotion/modals/AddBuildingModal";
import AddLotModal from "@/components/Promotion/modals/AddLotModal";
import AttributeChildLotModal from "@/components/Promotion/modals/AttributeChildLotModal";
import DissociateChildLotModal from "@/components/Promotion/modals/DissociateChildLotModal";
import DeleteLotModal from "@/components/Lots/modals/DeleteModal";
import SelectLot from "@/components/Lots/SelectLot";
import { mapGetters } from "vuex";

export default {
    components: {
        AddBuildingModal,
        AddLotModal,
        AttributeChildLotModal,
        DissociateChildLotModal,
        DeleteLotModal,
        SelectLot
    },

    props: {
        promotion: {
            type: Object,
            required: true
        }
    },

    data() {
        return {
            selectedBuildingId: null,
            selectedLotId: null,
            selectedChildLotId: null,
            selectedChildLotParent: null,
            modals: {
                createBuilding: false,
                editBuilding: false,
                createLot: false,
                editLot: false,
                addChildLot: false,
                attributeChildLot: false,
                dissociateChildLot: false,
                deleteLot: false
            }
        };
    },

    // mounted() {
    //     if(this.lots_main.length){
    //         this.$store.dispatch("lot/get");
    //     }
    // },

    computed: {
        ...mapGetters("auth", ["user"]),
        ...mapGetters("lot", ["floors","types"]),

        selectedBuilding() {
            if (!this.selectedBuildingId) {
                return null;
            }

            let filter = this.promotion.buildings.filter(
                b => b.id === this.selectedBuildingId
            );

            return filter.length > 0 ? filter[0] : null;
        },

        mainLots() {
            if (!this.selectedBuilding) {
                return [];
            }

            return this.selectedBuilding.lots.filter(s => !s.parent_id);
        },

        allPromotionLots() {
            return this.promotion.buildings
                .map(b => b.lots)
                .flat()
                .filter(l => !l.parent_id);
        },

        selectedLot() {
            if (!this.selectedLotId) {
                return null;
            }

            let filter = this.selectedBuilding.lots.filter(
                l => l.id === this.selectedLotId
            );

            return filter.length > 0 ? filter[0] : null;
        },

        selectedChildLot() {
            if (!this.selectedChildLotId || !this.selectedLot) {
                return null;
            }

            let filter = this.selectedLot.children.filter(
                l => l.id === this.selectedChildLotId
            );

            return filter.length > 0 ? filter[0] : null;
        },

        lotDisplay() {
            if (this.selectedChildLot) {
                return this.selectedChildLot;
            } else if (this.selectedLot) {
                return this.selectedLot;
            } else {
                return null;
            }
        },

        surfacePPETitle(){
            let villa_id = this.types.find(function(e) {
                if(e.title == "Villa"){
                    return e;
                } 
            }).id;
            return this.lotDisplay.lot_type_id == villa_id ? "SPB" : "PPE";            
        },
        surfaceHabitableTitle(){
            let villa_id = this.types.find(function(e) {
                if(e.title == "Villa"){
                    return e;
                } 
            }).id;
            let appart_id = this.types.find(function(e) {
                if(e.title == "Appartement"){
                    return e;
                } 
            }).id;
            switch (this.lotDisplay.lot_type_id) {
                case villa_id:
                    return "utile";
                case appart_id:
                    return "pondérée";
                default:
                    return "habitable";
            }
        },
    },

    methods: {
        selectBuilding(building) {
            this.selectedBuildingId = building.id;
        },

        selectLot(lot) {
            this.selectedLotId = lot.id;
        },

        selectChildLot(childLot) {
            this.selectedChildLotId = childLot.id;
        },

        clearBuilding() {
            this.selectedBuildingId = null;
            this.selectedLotId = null;
        },

        clearLot() {
            this.selectedLotId = null;
            this.clearChildLot();
        },

        clearChildLot() {
            this.selectedChildLotId = null;
        },

        openCreateBuilding() {
            this.modals.createBuilding = true;
        },

        openEditBuilding() {
            this.modals.editBuilding = true;
        },

        openCreateLot() {
            this.modals.createLot = true;
        },

        openEditLot() {
            this.modals.editLot = true;
        },

        openAddChildLot() {
            this.modals.addChildLot = true;
        },

        openAttributeChildLot() {
            this.modals.attributeChildLot = true;
        },

        openDissociateChildLot() {
            this.modals.dissociateChildLot = true;
        },

        openDeleteLot() {
            this.modals.deleteLot = true;
        },

        addBuilding(data) {
            this.modals.createBuilding = false;

            promotionApi
                .createBuilding(this.promotion.id, data)
                .then(res => {
                    this.promotion.buildings.push(res.data.data);
                });
        },

        editBuilding(data) {
            this.modals.editBuilding = false;
            
            let lotsTemp = data.lots;

            promotionApi
                .updateBuilding(
                    this.promotion.id,
                    this.selectedBuilding.id,
                    data
                )
                .then(res => {
                    let indexBuilding = this.promotion.buildings.findIndex(
                        b => b.id === this.selectedBuilding.id
                    );

                    this.promotion.buildings.splice(indexBuilding,1,res.data.data);
                    this.promotion.buildings[indexBuilding].lots = lotsTemp;
                });
        },

        addLot(data) {
            this.modals.createLot = false;

            promotionApi
                .createLot(this.promotion.id, this.selectedBuilding.id, data)
                .then(res => {
                    if (!this.selectedBuilding.lots) {
                        this.selectedBuilding.lots = [];
                    }

                    const indexBuilding = this.promotion.buildings.findIndex(
                        b => b.id === this.selectedBuilding.id
                    );

                    if (!this.promotion.buildings[indexBuilding].lots) {
                        this.promotion.buildings[indexBuilding].lots = [];
                    }

                    let newBuilding = { ...this.promotion.buildings[indexBuilding] };
                    newBuilding.lots.push(res.data.data);

                    this.$set(this.promotion.buildings, indexBuilding, newBuilding);
                });
        },

        editLot(data) {
            this.modals.editLot = false;

            promotionApi
                .updateLot(
                    this.promotion.id,
                    this.selectedBuilding.id,
                    this.selectedChildLot
                        ? this.selectedChildLot.id
                        : this.selectedLot.id,
                    data
                )
                .then(res => {
                    if (!this.selectedBuilding.lots) {
                        this.selectedBuilding.lots = [];
                    }

                    let indexBuilding = this.promotion.buildings.findIndex(
                        b => b.id === this.selectedBuilding.id
                    );

                    let newBuilding = { ...this.promotion.buildings[indexBuilding] };

                    let indexLot = newBuilding.lots.findIndex(
                        l => l.id === this.selectedLot.id
                    );

                    if (this.selectedChildLotId) {
                        let newLot = {
                            ...this.promotion.buildings[indexBuilding].lots[indexLot]
                        };
                        let index = newLot.children.findIndex(
                            l => l.id === this.selectedChildLot.id
                        );
                        newBuilding.lots[indexLot].children.splice(index, 1, res.data.data);
                    } else {
                        newBuilding.lots.splice(indexLot, 1, res.data.data);
                    }
                    this.$set(this.promotion.buildings, indexBuilding, newBuilding);
                });
        },

        addChildLot(data) {

            this.modals.addChildLot = false;

            promotionApi
                .createLot(this.promotion.id, this.selectedBuilding.id, data)
                .then(res => {
                    if (!this.selectedBuilding.lots) {
                        this.selectedBuilding.lots = [];
                    }

                    let indexBuilding = this.promotion.buildings.findIndex(
                        b => b.id === this.selectedBuilding.id
                    );

                    let newBuilding = { ...this.promotion.buildings[indexBuilding] };

                    let index = newBuilding.lots.findIndex(
                        l => l.id === this.selectedLot.id
                    );

                    newBuilding.lots[index].children.push(res.data.data);

                    this.$set(this.promotion.buildings, indexBuilding, newBuilding);
                });
        },

        attributeChildLot(data) {
            this.modals.attributeChildLot = false;
            this.selectedChildLotParent = data;
            promotionApi
                .updateLot(
                    this.promotion.id,
                    null,
                    this.selectedChildLotParent,
                    {
                        parent_id: this.selectedLot.id
                    }
                )
                .then(() => {
                    this.$emit("update");
                });
        },

        deleteLot() {
            this.modals.deleteLot = false;

            promotionApi
                .deleteLot(
                    this.promotion.id,
                    this.selectedBuilding.id,
                    this.lotDisplay.id, // On utilise lotDisplay qui contient soit le child soit le lot principal
                )
                .then(() => {
                    this.$emit("update");
                    this.clearLot();
                });
        },

        dissociateChildLot() {
            this.modals.dissociateChildLot = false;

            promotionApi
                .updateLot(
                    this.promotion.id,
                    null,
                    this.selectedChildLot.id,
                    {
                        parent_id: null,
                    }
                )
                .then(() => {
                    this.$emit("update");
                });
        },

        submitUpdateParentLot() {
            promotionApi
                .updateLot(
                    this.promotion.id,
                    null,
                    this.selectedChildLot.id,
                    {
                        parent_id: this.selectedChildLotParent
                    }
                )
                .then(res => {
                    if (!this.selectedBuilding.lots) {
                        this.selectedBuilding.lots = [];
                    }

                    let indexBuilding = this.promotion.buildings.findIndex(
                        b => b.id === this.selectedBuilding.id
                    );

                    let newBuilding = { ...this.promotion.buildings[indexBuilding] };

                    let indexLot = newBuilding.lots.findIndex(
                        l => l.id === this.selectedLot.id
                    );

                    let newIndexLot = newBuilding.lots.findIndex(
                        l => l.id === this.selectedChildLotParent
                    );

                    if (this.selectedChildLotId) {
                        let index = newBuilding.lots[indexLot].children.findIndex(
                            l => l.id === this.selectedChildLot.id
                        );
                        newBuilding.lots[indexLot].children.splice(index, 1);
                        newBuilding.lots[newIndexLot].children.push(res.data.data);
                    }

                    this.$set(this.promotion.buildings, indexBuilding, newBuilding);
                    this.selectedChildLotParent = null;
                    this.selectedChildLotId = null;
                });
        },

        updateSelectedChildLotParent(val) {
            this.selectedChildLotParent = val;
        }
    },

    watch: {}
};
</script>