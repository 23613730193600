<template>
    <main>   
        <div class="py-5 max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div class="flex items-center justify-between">
                <div class="text-right mb-2" v-if="isBroker">
                    <button
                        @click="downloadLots"
                        class="ml-3 pb-2 pt-1 px-3 border border-transparent text-sm font-medium rounded-sm text-white bg-blue-400 hover:bg-blue-600 focus:outline-none focus:border-green-700 transition duration-150 ease-in-out"
                    >
                        <svg class="inline-block w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path 
                                fill-rule="evenodd"
                                d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V7.414A2 2 0 0015.414 6L12 2.586A2 2 0 0010.586 2H6zm5 6a1 1 0 10-2 0v3.586l-1.293-1.293a1 1 0 10-1.414 1.414l3 3a1 1 0 001.414 0l3-3a1 1 0 00-1.414-1.414L11 11.586V8z"
                                clip-rule="evenodd"
                            >
                            </path
                        ></svg>
                        <span class="align-middle ml-2"
                            >Liste des lots</span
                        >
                    </button>
                </div>
            </div>
            <reset-query-link v-if="$route.query.lot || lotFilter"></reset-query-link>

            <lots-table :lots="lots" @update="fetchData"></lots-table>
        </div>
        <add-task-modal
            v-if="currentActionTarget && !isCandidate"
            :open="modals.addTask"
            targetOverride="lot"
            @createdTask="fetchData"
        ></add-task-modal>
        <show-task-modal
            v-if="!isCandidate"
            :open="modals.showTask"
            targetOverride="lot"
        ></show-task-modal>
        <edit-task-modal
            v-if="!isCandidate"
            :open="modals.editTask"
            targetOverride="lot"
            @update="fetchData"
        ></edit-task-modal>
        <update-price-modal 
            :open="modals.updatePrice" 
            @update="fetchData"
        ></update-price-modal>
        <ApplicationTooltip></ApplicationTooltip>
    </main>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import lot from "@/api/lot";

import LotsTable from "@/components/Lots/LotsTable";
import ShowTaskModal from "@/components/Candidates/modals/ShowTaskModal";
import EditTaskModal from "@/components/Candidates/modals/EditTaskModal";
import AddTaskModal from "@/components/Candidates/modals/AddTaskModal";
import UpdatePriceModal from "@/components/Lots/modals/UpdatePriceModal";
import ResetQueryLink from "@/components/global/ResetQueryLink";
import ApplicationTooltip from "@/components/Lots/ApplicationTooltip";
import View from "@/mixins/View";
import Auth from "@/mixins/Auth";

export default {
    mixins : [View, Auth],
    components: {
        LotsTable,
        ShowTaskModal,
        EditTaskModal,
        AddTaskModal,
        UpdatePriceModal,
        ResetQueryLink,
        ApplicationTooltip
    },

    data() {
        return {
            loading: true,
            modals: {
                showTask: false,
                editTask: false,
                addTask: false,
                updatePrice: false,
            },
        };
    },

    computed: {
        ...mapGetters("auth", ["currentPromotion"]),
        ...mapState("auth", ["user"]),
        ...mapState("application", ["applications"]),
        ...mapGetters("lot", ["lots"]),
        ...mapGetters("auth", ["preferences"]),
        ...mapGetters("app", ["formattedFilters"]),
        ...mapState("lot", [
            "currentActionTarget",
            "currentActionType",
        ]),
        lotFilter(){
            return this.$store.state.app.filters.lot;
        }
    },

    methods: {
        fetchData() {
            this.$store.dispatch("lot/get");
        },
        downloadLots() {
            lot.download({
                promotion : this.preferences.selected_promotion
            })
                .then((res) => {
                    window.open(res.data.url, "_blank");
                });
        },
    },

    watch: {
        currentActionType: function(newVal, oldVal) {
            if (newVal !== oldVal) {
                switch (newVal) {
                    case "addTask":
                        this.modals.addTask = true;
                        break;
                    case "showTask":
                        this.modals.showTask = true;
                        break;
                    case "editTask":
                        this.modals.editTask = true;
                        break;
                    case "updatePrice":
                        this.modals.updatePrice = true;
                        break;
                    default:
                        this.modals.addTask = false;
                        this.modals.showTask = false;
                        this.modals.editTask = false;
                        this.modals.updatePrice = false;
                }
            }
        },
        $route: {
            immediate: true,
            handler() {
                document.title = !this.isCandidate ? "E-Promotion | Lots" : "E-Promotion | Grille des prix";
            }
        },        
    },
};
</script>
