<template>
    <default-modal
        v-if="open"
        @close="close"
        maxWidthClass="max-w-3xl"
    >
        <div slot="header">
            <h3
                class="text-left text-xl leading-6 font-medium text-blue-900 pb-5"
            >
                Modifier le prix du lot {{ currentActionTarget.reference }}
            </h3>
        </div>

        <div slot="body" class="text-left">
            <div class="grid grid-cols-5">
                <input
                    type="number"
                    class="form-input col-span-2 text-right"
                    ref="input_price"
                    v-model="price"
                    step="1000"
                />
                <span class="col-span-3 align-bottom ml-2 leading-10">CHF</span>
            </div>

            <div
                class="mt-2 align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200"
            > 
            <span class="underline text-gray-700 font-medium">Lot(s) enfant(s) :</span>
                <table class="min-w-full">
                    <thead>
                        <tr>
                            <th
                                class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                            >
                                ID
                            </th>
                            <th
                                class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                            >
                                Type
                            </th>
                            <th
                                class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                            >
                                Taille
                            </th>
                            <th
                                class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                            >
                                Prix
                            </th>
                            <th 
                                class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                            >
                                Date d'ajout
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            class="bg-white text-center"
                            v-for="child in currentActionTarget.children"
                            :key="child.id"
                        >
                            <td
                                class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-600"
                            >
                                {{ child.reference }}
                            </td>
                            <td
                                class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-600"
                            >
                                {{ child.type.title }}
                            </td>
                            <td
                                class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-600"
                            >
                                {{
                                    child.area_hab
                                        ? child.area_hab + "m²"
                                        : "-"
                                }}
                            </td>
                            <td
                                class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-600"
                            >
                                {{ formatCHF(child.price) }}
                            </td>
                            <td
                                class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-600"
                            >
                                {{ child.created_at | date }}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="py-1">
                <span class="text-sm">
                    <span class="underline text-gray-700 font-medium">Calcul :</span>
                    <span class="text-gray-600">
                        {{formatCHF(currentActionTarget.raw_price)}}
                    </span>
                    <span v-for="child in currentActionTarget.children" :key="child.id" class="text-gray-600">
                        + {{formatCHF(child.price)}}
                    </span>
                    <span class="font-medium text-gray-700">
                        = {{formatCHF(currentActionTarget.total_price)}} CHF
                    </span>
                </span>
            </div>

        </div>
        <div slot="footer" class="mt-12 text-right">
            <button
                @click="close"
                type="button"
                class="inline-flex justify-center mr-4 w-48 rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-400 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            >
                Fermer
            </button>
            <button
                @click="submit"
                type="button"
                :disabled="!isValid"
                class="inline-flex justify-center w-48 rounded-md border border-gray-300 px-4 py-2 text-base leading-6 font-medium shadow-sm focus:outline-none focus:border-blue-400 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                :class="submitButtonClass"
            >
                Modifier
            </button>
        </div>
    </default-modal>
</template>

<script>
import DefaultModal from "@/components/global/modals/DefaultModal";
import { mapGetters, mapState } from "vuex";
import { formatCHF } from "@/helpers/formatter";


export default {
    components: { DefaultModal },
    data() {
        return {
            price: "",
            isDisabled: false,
        };
    },
    computed: {
        ...mapState("lot", ["currentActionTarget", "currentActionType"]),
        ...mapGetters("auth", ["currentPromotion"]),
        open(){
            return this.currentActionTarget && this.currentActionType === "updatePrice";
        },
        isValid() {
            if(this.isDisabled)
                return false;
            return this.price && this.price >= 0;
        },
        submitButtonClass() {
            return this.isValid
                ? "bg-blue-400 hover:bg-blue-600 text-gray-100 cursor-pointer"
                : "bg-gray-300 text-gray-700 cursor-not-allowed";
        }
    },
    methods: {
        formatCHF,
        init() {
            this.price = this.currentActionTarget.raw_price;
        },
        resetValues() {
            this.$store.commit("lot/SET_CURRENT_ACTION_TYPE", null);
            this.price = "";
        },
        close() {
            this.resetValues();
            this.$emit("close");
        },
        submit() {
            this.isDisabled = true;
            const body = {
                price: parseInt(this.price),
                id: this.currentActionTarget.id
            };

            this.$store
                .dispatch("lot/update", body)
                .then(res => {
                    if (res.data.code == 200) {
                        this.$toast.open({
                            type: "success",
                            message: "Lot mis à jour"
                        });
                        this.$emit("update");
                        this.close();
                    } else {
                        this.$toast.open({
                            type: "error",
                            message: res.data.error
                        });
                    }
                })
                .catch(err => {
                    this.$toast.open({
                        type: "error",
                        message: err
                    });
                })
                .finally(() => {
                    this.isDisabled = false;
                });
        }
    },
    watch: {
        open(val) {
            if(val)
                this.init();
        }
    }
};
</script>
