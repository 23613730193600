<template>
    <default-modal
        @close="close"
        maxWidthClass="max-w-3xl"
        v-if="open"
    >
        <h3
            slot="header"
            class="text-left text-xl leading-6 font-medium text-blue-900 pb-5"
        >
            Lots associés au lot {{ currentActionTarget.reference }}
        </h3>
        <div slot="body">
            <div class="mt-2" v-if="hasChildren">
                <p class="text-sm leading-5 text-gray-700 text-left">
                    Box, parking et caves liés au lot
                    <span class="font-semibold">{{ currentActionTarget.full_title }}</span> :
                </p>
                <div
                    class="mt-6 align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200"
                >
                    <table class="min-w-full">
                        <thead>
                            <tr>
                                <th
                                    class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Ref
                                </th>
                                <th
                                    class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Type
                                </th>
                                <th
                                    class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Nom
                                </th>
                                <th
                                    class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Taille
                                </th>
                                <th
                                    class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Prix
                                </th>
                                <th 
                                    v-if="!isCandidate"
                                    class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider"
                                >
                                    Date d'ajout
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                class="bg-white"
                                v-for="child in currentActionTarget.children"
                                :key="child.id"
                            >
                                <td
                                    class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-600"
                                >
                                    {{ child.reference }}
                                </td>
                                <td
                                    class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-600"
                                >
                                    {{ child.type.title }}
                                </td>
                                <td
                                    class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-600"
                                >
                                    {{ child.name_child }}
                                </td>
                                <td
                                    class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-600"
                                >
                                    {{
                                        child.area_hab
                                            ? child.area_hab + "m²"
                                            : "-"
                                    }}
                                </td>
                                <td
                                    class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-600"
                                >
                                    {{ formatCHF(child.price) }}
                                </td>
                                <td
                                    v-if="!isCandidate"
                                    class="px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-600"
                                >
                                    {{ child.created_at | date }}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="mt-2" v-else>
                <p class="text-sm leading-5 text-gray-700 text-left">
                    Aucun lot n'est associé au lot
                    <span class="font-semibold">{{ currentActionTarget.full_title }}</span
                    >.
                </p>
            </div>
        </div>
        <div slot="footer" class="mt-12 text-right">
            <button
                @click="close"
                type="button"
                class="inline-flex justify-center w-48 rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-400 focus:shadow-outline transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            >
                Fermer
            </button>
        </div>
    </default-modal>
</template>

<script>
import DefaultModal from "@/components/global/modals/DefaultModal";
import { formatCHF } from "@/helpers/formatter";
import Auth from "@/mixins/Auth";
import { mapGetters } from "vuex";

export default {
    mixins: [Auth],
    components: { DefaultModal },
    data() {
        return {};
    },
    computed: {
        ...mapGetters("lot",["currentActionTarget","currentActionType"]),
        hasChildren() {
            return this.currentActionTarget.children.length > 0;
        },
        open(){
            return this.currentActionTarget != null && this.currentActionType == "children";
        },
    },
    methods: {
        formatCHF,
        close() {
            this.$store.commit("lot/SET_CURRENT_ACTION_TARGET", null);
            this.$store.commit("lot/SET_CURRENT_ACTION_TYPE", null);
        },
    },
};
</script>
